.reviews {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 20px; */
    padding: 117px 0 75px;
  }

  .reviews .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .testimonials {
    position: relative;
    overflow: hidden;
    margin-top: 36px;
  }
  
  .testimonial-container {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .testimonial-container.next {
    animation: slideNext 0.5s forwards;
  }
  
  .testimonial-container.prev {
    animation: slidePrev 0.5s forwards;
  }
  
  @keyframes slideNext {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
  }
  
  @keyframes slidePrev {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
  }
  
  .testimonial-card {
    background-color: whitesmoke;
    border-radius: 25px;
    padding: 30px;
    width: calc(50% - 10px);
    margin: 0 5px;
    flex-shrink: 0;
  }
  
  .testimonial-content {
    position: relative;
    z-index: 1;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .user-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
  }
  
  .user-name {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  
  .user-role {
    color: #666;
    font-size: 14px;
  }
  
  .user-rating {
    color: #FFD700;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .testimonial-text {
    font-style: italic;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    /* max-width: 80%; */
    /* text-align: center; */
  }
  
  .quote-mark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 120px;
    color: #FFE082;
    /* font-family: Arial, sans-serif; */
    font-family: 'Poppins', sans-serif;
    z-index: 0;
    opacity: 0.3;
  }
  
  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0,0,0,0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 2;
    font-size: 18px;
    border-radius: 50%;
  }
  
  .left-btn {
    left: 10px;
  }
  
  .right-btn {
    right: 10px;
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      width: calc(100% - 10px);
    }
    
    .testimonial-container.next,
    .testimonial-container.prev {
      animation: none;
    }
  }

  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  