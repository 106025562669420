.container.coloc-section {
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
}

.coloc-details-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.coloc-details-title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-top: 1rem;
  color: #ff545a;
  max-width: 600px;
  font-weight: bolder;
}

.row.my-4 {
  padding: 8px 10px;
  max-width: 60%;
}

.section-header p {
  padding: 8px 0;
}

.img-fluid {
  width: 100%;
  height: 400px;
}

.d-flex.justify-content-between.mt-2 img {
  width: 100px;
}

h3 {
  font-family: 'Poppins', sans-serif;
}

p {
  padding: 10px 0;
}

.features {
  background-color: #f8fafb;
  padding: 20px;
  /* margin-left: 14px; ; */
  margin-right: 2px; ;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
}

.features h3 {
  font-size: 1.5rem;
  color: #ff545a;
  margin-bottom: 10px;
}

.card-hosted-by {
  margin-bottom: 20px;
  padding: 20px 15px;
  border: 2px solid #f8fafb;
}

.card-hosted-by .centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

.card-hosted-by .avatar {
  cursor: pointer;
}

.card-hosted-by img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.card-hosted-by h3 {
  margin: 0;
}

.card-hosted-by .badges {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
}

.card-hosted-by .badge {
  background-color: #ff545ab6;
}

.card-hosted-by .btn {
  width: 100%;
}

.card.form-card {
  padding: 20px;
}

.card.form-card .form-group {
  margin-bottom: 15px;
}

.feature-item {
  margin-bottom: 2rem;
}

.feature-item .d-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.feature-item .feature-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0;
  font-family: 'Poppins', sans-serif;
}

.feature-item .feature-icon-container .icon {
  margin-right: 0.5rem;
  color: #ff545a;
  font-size: 24px;
}

.feature-item .feature-icon-container h6 {
  margin: 0;
}

.coloc-similar-wrapper {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.coloc-similar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}

.coloc-similar-header h3 {
  color: #ff545a;
  font-size: 1.5rem;
  font-weight: 600;
}

.coloc-similar-header a {
  color: #ff545a;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 5rem;
}
.current-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.image-scroll-container .d-flex img {
  display: inline-block;
  margin-right: 10px;
}

.description-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.description-section h3 {
  font-size: 1.5rem;
  color: #ff545a;
  margin-bottom: 10px;
}

.description-section p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}
.coloc-details-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.coloc-details-section h3 {
  font-size: 1.5rem;
  color: #ff545a;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.coloc-details-section p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .coloc-details-title {
    font-size: 1.5rem;
    /* padding: 0 1rem; */
    text-align: left;
  }
  .container.coloc-section {
    padding: 10px 15px;
  }

  .coloc-similar-header {
    padding: 0 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .coloc-similar-header h3 {
    font-size: 1.25rem;
  }

  .coloc-similar-header a {
    padding-right: 0;
    font-weight: 500;
  }

  .container.coloc-section h1 {
    text-align: left;
    width: 100%;
  }

  .img-fluid {
    height: 200px;
  }

  .d-flex.justify-content-between.mt-2 img {
    width: 50px;
  }

  .card-hosted-by img {
    width: 50px;
    height: 50px;
  }

  .features,
  .card-hosted-by,
  .card.form-card {
    padding: 10px;
  }

  .feature-item .icon-container .icon {
    font-size: 20px;
  }
}

