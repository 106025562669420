.logement-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  max-width: 350px;
}

.action-button {
  padding: 0.2rem .5rem;
  border: none;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button.primary {
  border: 1px solid #ff545a;
  background-color: #ff545a;
  color: white;
}

.action-button.primary:hover {
  background-color: #db252b;
}

.action-button.secondary {
  border: 1px solid #ff545a;
  color: #ff545a;
}

.action-button.secondary:hover {
  background-color: #ff545a4d;
}

.action-button.green {
  border: 1px solid #28a745;
  background-color: #28a745;
  color: white;
}

.action-button.green:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .logement-actions {
      flex-direction: column;
  }

  .action-button {
      width: 100%;
  }
}
