.demand-details-container {
  padding: 24px;
  margin-top: 24px;
  border-radius: 10px;
  border: 1px solid #ff545a;
  font-family: 'Poppins', sans-serif;
}

.demand-details-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #ff545a;
}

.demand-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.demand-details-card {
  background-color: white;
  border-radius: 7px;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-icon {
  background-color: #ff545a; /* Pink */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.card-icon svg {
  width: 24px;
  height: 24px;
  color: white;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.card-text {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  max-height: 130px;
  overflow: hidden;
}

/* Custom styles for verification status */
.verification-status {
  grid-column: span 2; /* Span all columns */
  background-color: white;
  border-radius: 7px;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.verification-status .card-icon {
  background-color: #ff545a; /* Default to pink */
}

.verifiedd .card-icon {
  background-color: #28a745; /* Green for verified */
}

.unverified .card-icon {
  background-color: #ffc107; /* Yellow for unverified */
}

.verifiedd .card-text {
  color: #28a745; /* Green text for verified */
}

.unverified .card-text {
  color: #ffc107; /* Yellow text for unverified */
}

@media (max-width: 768px) {
  .demand-details-grid {
    grid-template-columns: 1fr;
  }
}
