.step-work {
    text-align: left;
    padding: 20px 42px;
    border-radius: 3px;
    box-shadow: 0 0px 2px rgba(71, 71, 71, .4);
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
    /* flex */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    width: auto;
}

.step-work h2 {
    font-size: 18px;
    margin: 35px 0 20px;
    color: #505866;
    font-weight: 700;
}

.step-work h2 a {
    font-size: 18px;
    margin: 35px 0 20px;
    color: #505866;
    font-weight: 700;
}

.step-work h2 a span {
    text-transform: lowercase;
}

.step-work p {
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    text-transform: initial;
}

.step-work:hover {
    box-shadow: 0 0px 10px rgba(71, 71, 71, .4);
    background: #ff545a;
}

.step-work:hover h2,
.step-work:hover h2 a {
    color: white;
    font-weight: bold;
}

.step-work:hover p {
    color: white;
}

.step-work img {
    transition: filter 0.3s ease, transform 0.3s ease;
}

.step-work:hover img {
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5));
    transform: scale(1.05);
}