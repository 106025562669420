:root {
    --ville-modal-primary-color: #ff545a;
    --ville-modal-secondary-color: white;
    --ville-modal-text-light: #000;
    --ville-modal-text-dark: #000;
    --ville-modal-text-muted: rgb(151 153 167);
    --ville-modal-google-text: rgb(219 206 253);
    --ville-modal-apple-text: rgb(81 87 108);
    --ville-modal-border-color: rgb(84 90 106);
    --ville-modal-overlay-color: rgba(0, 0, 0, 0.7);
    /* --ville-modal-gradient-start: #ff545ab9; */
    /* --ville-modal-gradient-end: rgb(58 60 84); */
}

.ville-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ville-modal-overlay-color);
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ville-modal-content {
    font-family: Arial, Helvetica, sans-serif;
    width: 1050px;
    background-color: var(--ville-modal-secondary-color);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid var(--ville-modal-border-color);
}

.ville-modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.ville-modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--ville-modal-text-light);
}

.ville-modal-title {
    color: var(--ville-modal-text-light);
    font-size: 26px;
    line-height: 26px;
    font-weight: 600;
    margin: 8px 0;
    text-align: center;
}

.ville-modal-description {
    color: var(--ville-modal-text-muted);
    font-size: 13px;
    font-weight: 600;
    margin: 8px 0;
    max-width: 90%;
    text-align: center;
    padding-left: 4rem;
}

.ville-modal-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.ville-modal-link {
    text-decoration: none;
}

.modal-link-title {
    color: var(--ville-modal-primary-color);
    font-size: 22px;
    font-weight: 600;
    /* margin: 8px 0; */
    text-align: center;
    /* background-color: green; */
}

.modal-link-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.ville-modal-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 15px;
    border-radius: 10px;
    text-decoration: none;
    padding: 24px;
    cursor: pointer;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
    width: 450px;
    height: 190px;
    text-decoration: none;
}

.ville-modal-card:hover {
    box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
    background-color: #ff545a;
    background: linear-gradient(to top left, #ff545ab2, #ff545a);
    color: white;
}

.ville-modal-card-icon {
    height: 35px;
    width: 35px;
    color: #ff545a;
}

.ville-modal-card:hover .ville-modal-card-icon {
    color: white;
}

.ville-modal-card:hover .ville-modal-card-desc {
    color: white;
}

.ville-modal-card:hover .ville-modal-link {
    color: white;
    text-decoration: none;
}

.ville-modal-card:hover .modal-link-title {
    color: white;
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 768px) {
    .ville-modal-content {
        width: 90%;
        padding: 10px;
    }

    .ville-modal-title {
        font-size: 20px;
        line-height: 22px;
    }

    .ville-modal-description {
        font-size: 12px;
        padding-left: 1rem;
    }

    .ville-modal-cards {
        flex-direction: column;
        gap: 5px;
    }

    .ville-modal-card {
        width: 100%;
        height: auto;
        padding: 15px;
    }

    .ville-modal-card-icon {
        height: 25px;
        width: 25px;
    }
}