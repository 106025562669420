.formContainer {
    background-color: #f8f9fa;
    padding: 20px 40px;
    border-radius: 5px;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3);
    font-family: 'Arial', sans-serif;
    max-width: 100%;
    margin: 0 auto;
}

.formTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #e74c3c;
}

.formGroup {
    margin-bottom: 1rem;
}

.formGroup .form-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
}

.formGroup .form-control {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}

.formGroup .invalid {
     color: red;
     margin-top: 8px;
     font-weight: 900;
}


.verification-status-valide {
    display: inline-block;
    background: #10b981;
    color: #fff;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.5em;
    vertical-align: middle;
}

.verification-status-non-valide {
    display: inline-block;
    background: #b91010;
    color: #fff;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.5em;
    vertical-align: middle;
}

/* .formGroup  input[type="file"] {
    background: #f8f9fa;
    display: block;
    width: 100%;
    outline: none;
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
} */

.formGroup .form-control:focus {
    outline: #e74c3c;
    box-shadow: none;
    border: 2px solid #e74c3c;
}

/* Media query */
/* @media (max-width: 968px) {
    .formContainer {
        background-color: red;
        padding: 20px;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .formContainer {
        background-color: green;
        padding: 20px;
        max-width: 100%;
    }
} */