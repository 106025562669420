.show-number-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .show-number-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 400px;
    text-align: center;
    position: relative;
  }
  
  .show-number-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #888;
  }
  
  .show-number-modal-icon {
    color: #ff545a;
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .show-number-modal-title {
    color: #ff545a;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .show-number-modal-description {
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .show-number-modal-call-text {
    color: #666;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .show-number-modal-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .show-number-modal-phone svg {
    color: #333;
    margin-right: 10px;
  }
  
  .show-number-modal-phone span {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }