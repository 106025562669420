.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    gap: .5rem;
  }
  
  .pagination-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #ff545a;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #ff545a;
  }
  
  .pagination-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px #ff545a;
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination-button svg {
    width: 1rem;
    height: 1rem;
  }
  
  .pagination-button:first-child svg {
    margin-right: 0.5rem;
  }
  
  .pagination-button:last-child svg {
    margin-left: 0.5rem;
  }
  
  .page-indicator {
    margin: 0 1rem;
    color: #4a5568;
  }