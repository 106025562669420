.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
    font-family: "Poppins","sans-serif";
  }
  
  h1 {
    font-size: 6rem;
    margin: 0;
    color: #ff545a;
  }
  
  h2 {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .home-link {
    color: white;
    text-decoration: none;
  }
  
  .home-link:hover {
    color: white;
    text-decoration: none;
  }