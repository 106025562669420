@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
.profile  a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: initial;
}
.profile  article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
.profile section {
  display: block;
}
.profile body {
  line-height: 1;
}
.profile ol,
.profile ul {
  list-style: none;
}
.profile blockquote,
.profile q {
  quotes: none;
}
.profile blockquote:after,
.profile blockquote:before,
.profile q:after,
.profile q:before {
  content: "";
  content: none;
}
.profile table {
  border-collapse: collapse;
  border-spacing: 0;
}
.profile .nice-form-group {
  --nf-input-size: 1.5rem;
  --nf-input-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-small-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-input-font-family: inherit;
  --nf-label-font-family: inherit;
  --nf-input-color: #20242f;
  --nf-input-border-radius: 0.5rem;
  --nf-input-placeholder-color: #929292;
  --nf-input-border-color: #c0c4c9;
  --nf-input-border-width: 1px;
  --nf-input-border-style: solid;
  --nf-input-border-bottom-width: 2px;
  --nf-input-focus-border-color: #3b4ce2;
  --nf-input-background-color: #f9fafb;
  --nf-invalid-input-border-color: var(--nf-input-border-color);
  --nf-invalid-input-background-color: var(--nf-input-background-color);
  --nf-invalid-input-color: var(--nf-input-color);
  --nf-valid-input-border-color: var(--nf-input-border-color);
  --nf-valid-input-background-color: var(--nf-input-background-color);
  --nf-valid-input-color: inherit;
  --nf-invalid-input-border-bottom-color: red;
  --nf-valid-input-border-bottom-color: green;
  --nf-label-font-size: var(--nf-small-font-size);
  --nf-label-color: #374151;
  --nf-label-font-weight: 500;
  --nf-slider-track-background: #dfdfdf;
  --nf-slider-track-height: 0.25rem;
  --nf-slider-thumb-size: calc(var(--nf-slider-track-height) * 4);
  --nf-slider-track-border-radius: var(--nf-slider-track-height);
  --nf-slider-thumb-border-width: 2px;
  --nf-slider-thumb-border-focus-width: 1px;
  --nf-slider-thumb-border-color: #fff;
  --nf-slider-thumb-background: var(--nf-input-focus-border-color);
  display: block;
  margin-top: calc(var(--nf-input-size) * 1.5);
  line-height: 1.5  ;
  white-space: nowrap;
  --switch-orb-size: var(--nf-input-size);
  --switch-orb-offset: calc(var(--nf-input-border-width) * 2);
  --switch-width: calc(var(--nf-input-size) * 2.5);
  --switch-height: calc(var(--nf-input-size) * 1.25 + var(--switch-orb-offset));
}
.profile .nice-form-group > label {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 2);
  white-space: normal;
}
.profile .nice-form-group > label + small {
  font-style: normal;
}
.profile .nice-form-group small {
  font-family: var(--nf-input-font-family);
  display: block;
  font-weight: 400;
  opacity: 0.75;
  font-size: var(--nf-small-font-size);
  margin-bottom: calc(var(--nf-input-size) * 0.75);
}
.profile .nice-form-group small:last-child {
  margin-bottom: 0;
}
.profile .nice-form-group > legend {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 5);
}
.profile .nice-form-group > .nice-form-group {
  margin-top: calc(var(--nf-input-size) / 2);
}
.profile .nice-form-group > input[type="checkbox"],
.profile .nice-form-group > input[type="date"],
.profile .nice-form-group > input[type="email"],
.profile .nice-form-group > input[type="month"],
.profile .nice-form-group > input[type="number"],
.profile .nice-form-group > input[type="password"],
.profile .nice-form-group > input[type="radio"],
.profile .nice-form-group > input[type="search"],
.profile .nice-form-group > input[type="tel"],
.profile .nice-form-group > input[type="text"],
.profile .nice-form-group > input[type="time"],
.profile .nice-form-group > input[type="url"],
.profile .nice-form-group > input[type="week"],
.profile .nice-form-group > select,
.profile .nice-form-group > textarea {
  background: var(--nf-input-background-color);
  font-family: inherit;
  font-size: var(--nf-input-font-size);
  border-bottom-width: var(--nf-input-border-width);
  font-family: var(--nf-input-font-family);
  box-shadow: none;
  border-radius: var(--nf-input-border-radius);
  border: var(--nf-input-border-width) var(--nf-input-border-style)
    var(--nf-input-border-color);
  border-bottom: var(--nf-input-border-bottom-width)
    var(--nf-input-border-style) var(--nf-input-border-color);
  color: var(--nf-input-color);
   width: 100%;/*width of the inputs */
  padding: calc(var(--nf-input-size) * 0.75);
  height: calc(var(--nf-input-size) * 2.75);
  line-height: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.15s ease-out;
  --icon-padding: calc(var(--nf-input-size) * 2.25);
  --icon-background-offset: calc(var(--nf-input-size) * 0.75);
}
.profile .nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="date"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="email"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="month"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="radio"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="tel"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="text"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="time"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="url"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > input[type="week"]:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > select:required:not(:placeholder-shown):invalid,
.profile .nice-form-group > textarea:required:not(:placeholder-shown):invalid {
  background-color: var(--nf-invalid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color)
    var(--nf-invalid-input-border-bottom-color);
  color: var(--nf-invalid-input-color);
}
.profile .nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="date"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="email"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="month"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="radio"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="tel"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="text"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="time"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="url"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group
  > input[type="week"]:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group > select:required:not(:placeholder-shown):invalid:focus,
.profile .nice-form-group > textarea:required:not(:placeholder-shown):invalid:focus {
  background-color: var(--nf-input-background-color);
  border-color: var(--nf-input-border-color);
  color: var(--nf-input-color);
}
.profile .nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="date"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="email"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="month"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="number"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="radio"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="search"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="tel"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="text"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="time"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="url"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > input[type="week"]:required:not(:placeholder-shown):valid,
.profile .nice-form-group > select:required:not(:placeholder-shown):valid,
.profile .nice-form-group > textarea:required:not(:placeholder-shown):valid {
  background-color: var(--nf-valid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color) var(--nf-valid-input-border-bottom-color);
  color: var(--nf-valid-input-color);
}
.profile .nice-form-group > input[type="checkbox"]:disabled,
.profile .nice-form-group > input[type="date"]:disabled,
.profile .nice-form-group > input[type="email"]:disabled,
.profile .nice-form-group > input[type="month"]:disabled,
.profile .nice-form-group > input[type="number"]:disabled,
.profile .nice-form-group > input[type="password"]:disabled,
.profile .nice-form-group > input[type="radio"]:disabled,
.profile .nice-form-group > input[type="search"]:disabled,
.profile .nice-form-group > input[type="tel"]:disabled,
.profile .nice-form-group > input[type="text"]:disabled,
.profile .nice-form-group > input[type="time"]:disabled,
.profile .nice-form-group > input[type="url"]:disabled,
.profile .nice-form-group > input[type="week"]:disabled,
.profile .nice-form-group > select:disabled,
.profile .nice-form-group > textarea:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.profile .nice-form-group > input[type="checkbox"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="date"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="email"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="month"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="number"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="password"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="radio"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="search"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="tel"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="text"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="time"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="url"]::-webkit-input-placeholder,
.profile .nice-form-group > input[type="week"]::-webkit-input-placeholder,
.profile .nice-form-group > select::-webkit-input-placeholder,
.profile .nice-form-group > textarea::-webkit-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.profile .nice-form-group > input[type="checkbox"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="date"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="email"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="month"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="number"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="password"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="radio"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="search"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="tel"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="text"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="time"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="url"]:-ms-input-placeholder,
.profile .nice-form-group > input[type="week"]:-ms-input-placeholder,
.profile .nice-form-group > select:-ms-input-placeholder,
.profile .nice-form-group > textarea:-ms-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.profile .nice-form-group > input[type="checkbox"]:-moz-placeholder,
.profile .nice-form-group > input[type="checkbox"]::-moz-placeholder,
.profile .nice-form-group > input[type="date"]:-moz-placeholder,
.profile .nice-form-group > input[type="date"]::-moz-placeholder,
.profile .nice-form-group > input[type="email"]:-moz-placeholder,
.profile .nice-form-group > input[type="email"]::-moz-placeholder,
.profile .nice-form-group > input[type="month"]:-moz-placeholder,
.profile .nice-form-group > input[type="month"]::-moz-placeholder,
.profile .nice-form-group > input[type="number"]:-moz-placeholder,
.profile .nice-form-group > input[type="number"]::-moz-placeholder,
.profile .nice-form-group > input[type="password"]:-moz-placeholder,
.profile .nice-form-group > input[type="password"]::-moz-placeholder,
.profile .nice-form-group > input[type="radio"]:-moz-placeholder,
.profile .nice-form-group > input[type="radio"]::-moz-placeholder,
.profile .nice-form-group > input[type="search"]:-moz-placeholder,
.profile .nice-form-group > input[type="search"]::-moz-placeholder,
.profile .nice-form-group > input[type="tel"]:-moz-placeholder,
.profile .nice-form-group > input[type="tel"]::-moz-placeholder,
.profile .nice-form-group > input[type="text"]:-moz-placeholder,
.profile .nice-form-group > input[type="text"]::-moz-placeholder,
.profile .nice-form-group > input[type="time"]:-moz-placeholder,
.profile .nice-form-group > input[type="time"]::-moz-placeholder,
.profile .nice-form-group > input[type="url"]:-moz-placeholder,
.profile .nice-form-group > input[type="url"]::-moz-placeholder,
.profile .nice-form-group > input[type="week"]:-moz-placeholder,
.profile .nice-form-group > input[type="week"]::-moz-placeholder,
.profile .nice-form-group > select:-moz-placeholder,
.profile .nice-form-group > select::-moz-placeholder,
.profile .nice-form-group > textarea:-moz-placeholder,
.profile .nice-form-group > textarea::-moz-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.profile .nice-form-group > input[type="checkbox"]:focus,
.profile .nice-form-group > input[type="date"]:focus,
.profile .nice-form-group > input[type="email"]:focus,
.profile .nice-form-group > input[type="month"]:focus,
.profile .nice-form-group > input[type="number"]:focus,
.profile .nice-form-group > input[type="password"]:focus,
.profile .nice-form-group > input[type="radio"]:focus,
.profile .nice-form-group > input[type="search"]:focus,
.profile .nice-form-group > input[type="tel"]:focus,
.profile .nice-form-group > input[type="text"]:focus,
.profile .nice-form-group > input[type="time"]:focus,
.profile .nice-form-group > input[type="url"]:focus,
.profile .nice-form-group > input[type="week"]:focus,
.profile .nice-form-group > select:focus,
.profile .nice-form-group > textarea:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="checkbox"] + small,
.profile .nice-form-group > input[type="date"] + small,
.profile .nice-form-group > input[type="email"] + small,
.profile .nice-form-group > input[type="month"] + small,
.profile .nice-form-group > input[type="number"] + small,
.profile .nice-form-group > input[type="password"] + small,
.profile .nice-form-group > input[type="radio"] + small,
.profile .nice-form-group > input[type="search"] + small,
.profile .nice-form-group > input[type="tel"] + small,
.profile .nice-form-group > input[type="text"] + small,
.profile .nice-form-group > input[type="time"] + small,
.profile .nice-form-group > input[type="url"] + small,
.profile .nice-form-group > input[type="week"] + small,
.profile .nice-form-group > select + small,
.profile .nice-form-group > textarea + small {
  margin-top: 0.5rem;
}
.profile .nice-form-group > input[type="checkbox"].icon-left,
.profile .nice-form-group > input[type="date"].icon-left,
.profile .nice-form-group > input[type="email"].icon-left,
.profile .nice-form-group > input[type="month"].icon-left,
.profile .nice-form-group > input[type="number"].icon-left,
.profile .nice-form-group > input[type="password"].icon-left,
.profile .nice-form-group > input[type="radio"].icon-left,
.profile .nice-form-group > input[type="search"].icon-left,
.profile .nice-form-group > input[type="tel"].icon-left,
.profile .nice-form-group > input[type="text"].icon-left,
.profile .nice-form-group > input[type="time"].icon-left,
.profile .nice-form-group > input[type="url"].icon-left,
.profile .nice-form-group > input[type="week"].icon-left,
.profile .nice-form-group > select.icon-left,
.profile .nice-form-group > textarea.icon-left {
  background-position: left var(--icon-background-offset) bottom 50%;
  padding-left: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.profile .nice-form-group > input[type="checkbox"].icon-right,
.profile .nice-form-group > input[type="date"].icon-right,
.profile .nice-form-group > input[type="email"].icon-right,
.profile .nice-form-group > input[type="month"].icon-right,
.profile .nice-form-group > input[type="number"].icon-right,
.profile .nice-form-group > input[type="password"].icon-right,
.profile .nice-form-group > input[type="radio"].icon-right,
.profile .nice-form-group > input[type="search"].icon-right,
.profile .nice-form-group > input[type="tel"].icon-right,
.profile .nice-form-group > input[type="text"].icon-right,
.profile .nice-form-group > input[type="time"].icon-right,
.profile .nice-form-group > input[type="url"].icon-right,
.profile .nice-form-group > input[type="week"].icon-right,
.profile .nice-form-group > select.icon-right,
.profile .nice-form-group > textarea.icon-right {
  background-position: right var(--icon-background-offset) bottom 50%;
  padding-right: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.profile .nice-form-group > input[type="checkbox"]:-webkit-autofill,
.profile .nice-form-group > input[type="date"]:-webkit-autofill,
.profile .nice-form-group > input[type="email"]:-webkit-autofill,
.profile .nice-form-group > input[type="month"]:-webkit-autofill,
.profile .nice-form-group > input[type="number"]:-webkit-autofill,
.profile .nice-form-group > input[type="password"]:-webkit-autofill,
.profile .nice-form-group > input[type="radio"]:-webkit-autofill,
.profile .nice-form-group > input[type="search"]:-webkit-autofill,
.profile .nice-form-group > input[type="tel"]:-webkit-autofill,
.profile .nice-form-group > input[type="text"]:-webkit-autofill,
.profile .nice-form-group > input[type="time"]:-webkit-autofill,
.profile .nice-form-group > input[type="url"]:-webkit-autofill,
.profile .nice-form-group > input[type="week"]:-webkit-autofill,
.profile .nice-form-group > select:-webkit-autofill,
.profile .nice-form-group > textarea:-webkit-autofill {
  padding: calc(var(--nf-input-size) * 0.75) !important;
}
.profile .nice-form-group > input[type="search"]:placeholder-shown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cpath d='M21 21l-4.35-4.35'/%3E%3C/svg%3E");
  background-position: left calc(var(--nf-input-size) * 0.75) bottom 50%;
  padding-left: calc(var(--nf-input-size) * 2.25);
  background-size: var(--nf-input-size);
  background-repeat: no-repeat;
}
.profile .nice-form-group > input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cpath d='M18 6L6 18M6 6l12 12'/%3E%3C/svg%3E");
}
.profile .nice-form-group > input[type="search"]:focus {
  padding-left: calc(var(--nf-input-size) * 0.75);
  background-position: left calc(var(--nf-input-size) * -1) bottom 50%;
}
.profile .nice-form-group > input[type="email"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-at-sign'%3E%3Ccircle cx='12' cy='12' r='4'/%3E%3Cpath d='M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.profile .nice-form-group > input[type="tel"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone'%3E%3Cpath d='M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.profile .nice-form-group > input[type="url"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-link'%3E%3Cpath d='M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71'/%3E%3Cpath d='M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.profile .nice-form-group > input[type="password"] {
  letter-spacing: 2px;
}
.profile .nice-form-group > input[type="password"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-lock'%3E%3Crect x='3' y='11' width='18' height='11' rx='2' ry='2'/%3E%3Cpath d='M7 11V7a5 5 0 0110 0v4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.profile .nice-form-group > input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
}
.profile .nice-form-group > input[type="range"]:focus {
  outline: none;
}
.profile .nice-form-group > input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.profile .nice-form-group > input[type="range"]::-moz-range-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.profile .nice-form-group > input[type="range"]::-webkit-slider-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -webkit-appearance: none;
  appearance: none;
  margin-top: calc(
    var(--nf-slider-track-height) * 0.5 - var(--nf-slider-thumb-size) * 0.5
  );
}
.profile .nice-form-group > input[type="range"]::-moz-range-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
.profile .nice-form-group > input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.profile .nice-form-group > input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.profile .nice-form-group > input[type="color"] {
  border: var(--nf-input-border-width) solid var(--nf-input-border-color);
  border-bottom-width: var(--nf-input-border-bottom-width);
  height: calc(var(--nf-input-size) * 2);
  border-radius: var(--nf-input-border-radius);
  padding: calc(var(--nf-input-border-width) * 2);
}
.profile .nice-form-group > input[type="color"]:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 5%;
}
.profile .nice-form-group > input[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.profile .nice-form-group > input[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.profile .nice-form-group > input[type="number"] {
  width: auto;
}
.profile .nice-form-group > input[type="date"],
.profile .nice-form-group > input[type="month"],
.profile .nice-form-group > input[type="week"] {
  min-width: 14em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-calendar'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'/%3E%3Cpath d='M16 2v4M8 2v4M3 10h18'/%3E%3C/svg%3E");
}
.profile .nice-form-group > input[type="time"] {
  min-width: 6em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-clock'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M12 6v6l4 2'/%3E%3C/svg%3E");
}
.profile .nice-form-group > input[type="date"],
.profile .nice-form-group > input[type="month"],
.profile .nice-form-group > input[type="time"],
.profile .nice-form-group > input[type="week"] {
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
  width: auto;
}
.profile .nice-form-group > input[type="date"]::-webkit-calendar-picker-indicator,
.profile .nice-form-group > input[type="date"]::-webkit-inner-spin-button,
.profile .nice-form-group > input[type="month"]::-webkit-calendar-picker-indicator,
.profile .nice-form-group > input[type="month"]::-webkit-inner-spin-button,
.profile .nice-form-group > input[type="time"]::-webkit-calendar-picker-indicator,
.profile .nice-form-group > input[type="time"]::-webkit-inner-spin-button,
.profile .nice-form-group > input[type="week"]::-webkit-calendar-picker-indicator,
.profile .nice-form-group > input[type="week"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  opacity: 0;
}
@-moz-document url-prefix() {
  .profile .nice-form-group > input[type="date"],
  .profile .nice-form-group > input[type="month"],
  .profile .nice-form-group > input[type="time"],
  .profile .nice-form-group > input[type="week"] {
    min-width: auto;
    width: auto;
    background-image: none;
  }
}
.profile .nice-form-group > textarea {
  height: auto;
}
.profile .nice-form-group > input[type="checkbox"],
.profile .nice-form-group > input[type="radio"] {
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  padding: inherit;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border-width: var(--nf-input-border-width);
  cursor: pointer;
  background-position: 50%;
}
.profile .nice-form-group > input[type="checkbox"]:focus:not(:checked),
.profile .nice-form-group > input[type="radio"]:focus:not(:checked) {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
  outline: none;
}
.profile .nice-form-group > input[type="checkbox"]:hover,
.profile .nice-form-group > input[type="radio"]:hover {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="checkbox"] + label,
.profile .nice-form-group > input[type="radio"] + label {
  display: inline-block;
  margin-bottom: 0;
  padding-left: calc(var(--nf-input-size) / 2.5);
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  max-width: calc(100% - var(--nf-input-size) * 2);
  line-height: normal;
}
.profile .nice-form-group > input[type="checkbox"] + label > small,
.profile .nice-form-group > input[type="radio"] + label > small {
  margin-top: calc(var(--nf-input-size) / 4);
}
.profile .nice-form-group > input[type="checkbox"]:checked {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E")
    no-repeat 50%/85%;
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="radio"] {
  border-radius: 100%;
}
.profile .nice-form-group > input[type="radio"]:checked {
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
  box-shadow: inset 0 0 0 3px #fff;
}
.profile .nice-form-group > input[type="checkbox"].switch {
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-height);
  position: relative;
}
.profile .nice-form-group > input[type="checkbox"].switch:after {
  background: var(--nf-input-border-color);
  border-radius: var(--switch-orb-size);
  height: var(--switch-orb-size);
  left: var(--switch-orb-offset);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: var(--switch-orb-size);
  content: "";
  transition: all 0.2s ease-out;
}
.profile .nice-form-group > input[type="checkbox"].switch + label {
  margin-top: calc(var(--switch-height) / 8);
}
.profile .nice-form-group > input[type="checkbox"].switch:checked {
  background: none;
  background-position: 0 0;
  background-color: var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="checkbox"].switch:checked:after {
  -webkit-transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  background: #fff;
}
.profile .nice-form-group > input[type="file"] {
  background: rgba(0, 0, 0, 0.025);
  padding: var(--nf-input-size);
  display: block;
  width: 100%;
  border-radius: var(--nf-input-border-radius);
  border: 1px dashed var(--nf-input-border-color);
  outline: none;
  cursor: pointer;
}
.profile .nice-form-group > input[type="file"]:focus,
.profile .nice-form-group > input[type="file"]:hover {
  border-color: var(--nf-input-focus-border-color);
}
.profile .nice-form-group > input[type="file"]::file-selector-button {
  background: var(--nf-input-focus-border-color);
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5rem;
  border-radius: var(--nf-input-border-radius);
  color: #fff;
  margin-right: 1rem;
  outline: none;
  font-family: var(--nf-input-font-family);
  cursor: pointer;
}
.profile .nice-form-group > input[type="file"]::-webkit-file-upload-button {
  background: var(--nf-input-focus-border-color);
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  padding: 0.5rem;
  border-radius: var(--nf-input-border-radius);
  color: #fff;
  margin-right: 1rem;
  outline: none;
  font-family: var(--nf-input-font-family);
  cursor: pointer;
}
.profile .nice-form-group > select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
}
    

body .profile {
  min-height: 60vh;
  background: #f3f0e7;
  font-family: Roboto, sans-serif;
  color: #4b5563;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 2em;
}


.profile .demo-page {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  max-width: 70em;
}

.profile .demo-page p {
  max-width: 50em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.profile .demo-page .demo-page-navigation {
  width: 18em;
  padding: 2em 1em;
}
@media only screen and (max-width: 750px) {
  .profile .demo-page .demo-page-navigation {
    display: none;
  }
}
.profile .demo-page .demo-page-navigation nav {
  position: -webkit-sticky;
  position: sticky;
  top: 2em;
  background: #fff;
  padding: 0.5em;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.profile .demo-page .demo-page-navigation a {
  display: -webkit-flex;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
  border-radius: 0.25em;
  color: #374151;
  -webkit-align-items: center;
  align-items: center;
}
.profile .demo-page .demo-page-navigation a:hover {
  background: #f3f4f6;
}
.profile .demo-page .demo-page-navigation a svg {
  width: 1.25em;
  height: 1.25em;
  margin-right: 1em;
  color: #1f2937;
}
.profile .demo-page .demo-page-content {
  padding: 2em 1em;
  max-width: 100%;
}
@media only screen and (min-width: 750px) {
  .profile .demo-page .demo-page-content {
    width: calc(100% - 18em);
  }
}
footer .profile {
  text-align: center;
  margin: 2.5em 0;
}
.profile .href-target {
  position: absolute;
  top: -2em;
}
.profile .to-repo,
.profile .to-reset {
  display: -webkit-inline-flex;
  display: inline-flex;
  background: #24292e;
  color: #fff;
  border-radius: 5px;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-align-items: center;
  align-items: center;
  transition: background 0.2s ease-out;
}
.profile .to-repo:hover,
.profile .to-reset:hover {
  background: #000;
}
.profile .to-repo svg,
.profile .to-reset svg {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75em;
}
.profile .to-reset {
  background: #3b4ce2;
}
.profile .to-reset:hover {
  background: #2538df;
}
.profile section {
  background: #fff;
  padding: 2em;
  border-radius: 0.75rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
  font-size: 0.875rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.profile section h1 {
  font-weight: 500;
  font-size: 1.25rem;
  color: #000;
  margin-bottom: 0.75rem;
}
.profile section h1 svg {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -10%;
  margin-right: 0.25em;
}
.profile section h1.package-name {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  margin-top: -0.5rem;
}
.profile section strong {
  font-weight: 500;
  color: #000;
}
.profile section p {
  margin: 0.5rem 0 1.5rem;
}
.profile section p a {
  text-decoration: none;
  font-weight: 500;
  color: #3b4ce2;
}
.profile section p:last-child {
  margin-bottom: 0;
}
.profile section code {
  font-weight: 500;
  font-family: Consolas, monaco, monospace;
  position: relative;
  z-index: 1;
  margin: 0 2px;
  background: #f3f4f4;
  content: "";
  border-radius: 3px;
  padding: 2px 5px;
  white-space: nowrap;
}
.profile section ul {
  margin-top: 0.5em;
  padding-left: 1em;
  list-style-type: disc;
}
.profile details {
  background: #f1f1f1;
  margin: 2em -2em -2em;
  padding: 1.5em 2em;
}
.profile details .gist {
  margin-top: 1.5em;
}
.profile details .toggle-code {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.875rem;
  background: #10b981;
  top: 1em;
  right: 1em;
  color: #fff;
  font-weight: 500;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.profile details .toggle-code:hover {
  background: #0ea271;
}
.profile details .toggle-code svg {
  display: inline-block;
  vertical-align: -15%;
  margin-right: 0.25em;
}
.profile details summary {
  outline: none;
  list-style-type: none;
}
.profile details summary::marker {
  display: none;
}
.profile details summary::-webkit-.profile details-marker {
  display: none;
}


  
  .profile .verification-status-valide {
    display: inline-block;
    background: #10b981;
    color: #fff;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.5em;
    vertical-align: middle;
  }

   .profile .verification-status-non-valide {
    display: inline-block;
    background: #b91010;
    color: #fff;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.5em;
    vertical-align: middle;
  }

.profile-nav, .profile-info{
    margin-top:30px;   
}

.profile-nav .user-heading {
    /* background: #ff545a; */
    /* color: #fff; */
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    padding: 30px;
    text-align: center;
}

.profile-nav .user-heading.round a  {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 10px solid rgba(255,255,255,0.3);
    display: inline-block;
}

.profile-nav .user-heading  img {
    width: 112px;
    height: 112px;
    /* object-fit: cover; */
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.profile-nav .user-heading h1 {
    font-size: 22px;
    font-weight: 300;

    margin-bottom: 5px;
    padding: 10px 0;
}

.profile-nav .user-heading p {
    font-size: 14px;
    background-color: #b910102c;
    border-radius: 15px;
    color: #000;
}

.profile-nav ul {
    margin-top: 1px;
}

.profile-nav ul > li {
    border-bottom: 1px solid #ebeae6;
    margin-top: 0;
    line-height: 30px;
}

.profile-nav ul > li:last-child {
    border-bottom: none;
}

.profile-nav ul > li > a {
    border-radius: 0;
    -webkit-border-radius: 0;
    color: #89817f;
    border-left: 5px solid #fff;
}

.profile-nav ul > li > a:hover, .profile-nav ul > li > a:focus, .profile-nav ul li.active  a {
    background: #f8f7f5 !important;
    border-left: 5px solid #ff545a;
    color: #89817f !important;
}

.profile-nav ul > li:last-child > a:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

.profile-nav ul > li > a > i{
    font-size: 16px;
    padding-right: 10px;
    color: #bcb3aa;
}

.r-activity {
    margin: 6px 0 0;
    font-size: 12px;
}


.p-text-area, .p-text-area:focus {
    border: none;
    font-weight: 300;
    box-shadow: none;
    color: #c3c3c3;
    font-size: 16px;
}

.profile-info .panel-footer {
    border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
    color: #7a7a7a;
}

.bio-graph-heading {
    background: #ff545a;
    color: #fff;
    text-align: center;
    font-style: italic;
    padding: 40px 110px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: 300;
}

.bio-graph-info {
    color: #89817e;
}

.bio-graph-info h1 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 20px;
}

.bio-row {
    width: 50%;
    float: left;
    margin-bottom: 10px;
    padding:0 15px;
}

.bio-row p span {
    width: 100px;
    display: inline-block;
}

.bio-chart, .bio-desk {
    float: left;
}

.bio-chart {
    width: 40%;
}

.bio-desk {
    width: 60%;
}

.bio-desk h4 {
    font-size: 15px;
    font-weight:400;
}

.bio-desk h4.terques {
    color: #4CC5CD;
}

.bio-desk h4.red {
    color: #e26b7f;
}

.bio-desk h4.green {
    color: #97be4b;
}

.bio-desk h4.purple {
    color: #caa3da;
}

.file-pos {
    margin: 6px 0 10px 0;
}

.profile-activity h5 {
    font-weight: 300;
    margin-top: 0;
    color: #c3c3c3;
}

.summary-head {
    background: #ee7272;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #ee7272;
}

.summary-head h4 {
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.summary-head p {
    color: rgba(255,255,255,0.6);
}

ul.summary-list {
    display: inline-block;
    padding-left:0 ;
    width: 100%;
    margin-bottom: 0;
}

ul.summary-list > li {
    display: inline-block;
    width: 19.5%;
    text-align: center;
}

ul.summary-list > li > a > i {
    display:block;
    font-size: 18px;
    padding-bottom: 5px;
}

ul.summary-list > li > a {
    padding: 10px 0;
    display: inline-block;
    color: #818181;
}

ul.summary-list > li  {
    border-right: 1px solid #eaeaea;
}

ul.summary-list > li:last-child  {
    border-right: none;
}

.activity {
    width: 100%;
    float: left;
    margin-bottom: 10px;
}

.activity.alt {
    width: 100%;
    float: right;
    margin-bottom: 10px;
}

.activity span {
    float: left;
}

.activity.alt span {
    float: right;
}
.activity span, .activity.alt span {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #eee;
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.activity.terques span {
    background: #8dd7d6;
}

.activity.terques h4 {
    color: #8dd7d6;
}
.activity.purple span {
    background: #b984dc;
}

.activity.purple h4 {
    color: #b984dc;
}
.activity.blue span {
    background: #90b4e6;
}

.activity.blue h4 {
    color: #90b4e6;
}
.activity.green span {
    background: #aec785;
}

.activity.green h4 {
    color: #aec785;
}

.activity h4 {
    margin-top:0 ;
    font-size: 16px;
}

.activity p {
    margin-bottom: 0;
    font-size: 13px;
}

.activity .activity-desk i, .activity.alt .activity-desk i {
    float: left;
    font-size: 18px;
    margin-right: 10px;
    color: #bebebe;
}

.activity .activity-desk {
    margin-left: 70px;
    position: relative;
}

.activity.alt .activity-desk {
    margin-right: 70px;
    position: relative;
}

.activity.alt .activity-desk .panel {
    float: right;
    position: relative;
}

.activity-desk .panel {
    display: inline-block;
}


.activity .activity-desk .arrow {
    border-right: 8px solid #F4F4F4 !important;
}
.activity .activity-desk .arrow {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activity-desk .arrow-alt {
    border-left: 8px solid #F4F4F4 !important;
}

.activity-desk .arrow-alt {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    right: -7px;
    position: absolute;
    top: 13px;
    width: 0;
}

.activity-desk .album {
    display: inline-block;
    margin-top: 10px;
}

.activity-desk .album a{
    margin-right: 10px;
}

.activity-desk .album a:last-child{
    margin-right: 0px;
}

.profile-bg {
  background: #f8f7f5;
}

.annonces-page {
  background: #ee7272;
  min-height: 100vh;
  padding: 20px;
}

.annonces-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.annonces-content {
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}

.annonces-tabs {
  display: flex;
  overflow: hidden;
}

.annonces-tab-item {
  flex: 1;
}

.annonces-tab-link {
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background: #343436;
  color: #fff;
  border: none;
  width: 100%;
  height: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.annonces-tab-link.active {
  background: #fff;
  color: #000;
}

.annonces-tab-content {
  position: relative;
  background: #fff;
  color: #000;
  border-radius: 0 0 30px 30px;
  z-index: 1000;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
  padding: 30px;
  margin-top: -20px;
}

.annonces-tab-pane {
  display: block;
}

@media (max-width: 768px) {
  .annonces-content {
    border-radius: 15px;
  }
  
  .annonces-tab-link {
    height: 80px;
    padding: 15px;
  }
}
.nav-link-button {
  background: none;
  border: none;
  color: #337ab7;
  text-align: left;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
}

.nav-link-button:hover {
  text-decoration: underline;
  color: #23527c;
}

.nav-link-button:focus {
  outline: none;
}

.nav-link-button .fa {
  margin-right: 5px;
}

.nav-link-button .label {
  margin-left: 10px;
}
