.blog-list {
    margin-top: 35px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align cards to the left */
}

.blog-card {
    flex: 0 1 calc(33.333% - 20px); /* 3 cards per row */
    margin: 10px; /* Space between cards */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visuals */
    border-radius: 5px; /* Optional: Rounded corners */
    overflow: hidden; /* Ensure children fit within rounded corners */
    background-color: white; /* Card background color */
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .blog-card {
        flex: 0 1 calc(50% - 20px); /* 2 cards per row for medium screens */
    }
}

@media (max-width: 768px) {
    .blog-card {
        flex: 0 1 calc(100% - 20px); /* 1 card per row for small screens */
    }
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-controls button {
    background-color: #ff545a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 16px;
}

.pagination-controls button:disabled {
    background-color: #ccc; /* Grey color for disabled buttons */
    cursor: not-allowed; /* Change cursor for disabled state */
}
