.profile-nav {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 2px #ff4757;
    padding: 2rem;
    /* max-width: 300px; */
    margin-top: 10px;
    border: 2px solid #ff4757;
    font-family: 'Poppins',sans-serif;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ff4757;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    color: white;
    font-size: 24px;
  }
  
  .user-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.5rem 0;
    text-align: center;
  }
  
  .user-email {
    font-size: 1rem;
    font-weight: 700;
    color: #666;
    margin: 0;
  }
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nav-item:hover {
    background-color: #ff4756de;
    color: white;
  }
  
  .nav-item.active {
    background-color: #ff4757;
    font-weight: 600;
    color: white;
  }
  
  .nav-icon {
    margin-right: 0.75rem;
  }
  
  .badge {
    margin-left: auto;
    background-color: #ff4757;
    color: white;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
  }