.error-alert {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .error-message {
    flex-grow: 1;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    line-height: 1;
    color: #721c24;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #491217;
  }
  