/* ProfileInfoCard.css */
.profile-info-card {
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    padding: 24px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    border : 1px solid #ff545a;
    margin-top: 10px;

  }
  
  .profile-info-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #ff545a;
    margin-bottom: 24px;

  }
  
  .profile-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .profile-info-item {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 5px 16px;
    transition: all 0.3s ease;
  }
  
  .profile-info-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .profile-info-label {
    display: flex;
    align-items: center;
    /* margin-bottom: 8px; */
    font-weight: 800;
    color: #ff545a;

  }
  
  .profile-info-label span {
    margin-left: 8px;
  }
  
  .profile-info-value {
    color: #000;
    font-size: 18px;
    margin: 0;
    word-break: break-word;
  }
  
  @media (max-width: 768px) {
    .profile-info-grid {
      grid-template-columns: 1fr;
    }
  }