.housing-info {
    background-color: #ffffff;
    border-radius: 5px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .housing-info h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 0.5rem;
  }

  .housing-details {
    /* background-color: green; */
    display: flex;
    flex-direction: column;
  }
  
  .housing-title {
    color: #444;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .detail-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  
  .detail-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .icon-container {
    background-color: #ff6b6b;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
  
  .icon-container svg {
    margin-left: 9px;
    color: white;
  }
  
  .item-content {
    display: flex;
    flex-direction: column;
  }
  
  .item-label {
    font-weight: bold;
    color: #666;
    margin-bottom: 0.25rem;
  }
  
  .item-value {
    color: #333;
  }
  
  /* Style for the description item */
  .detail-item:last-child {
    grid-column: 1 / -1;
    /* margin-top: 1.5rem; */
    /* border-top: 1px solid #e0e0e0; */
    /* padding-top: 1.5rem; */
  }
  
  .detail-item:last-child .item-value {
    margin-top: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .detail-items {
      grid-template-columns: 1fr;
    }
  }