.formContainer-review {
    max-width: 50% !important;
    /* margin-top: 36px; */
}

/* Media Query for devices with width up to 768px */
@media (max-width: 768px) {
    .formContainer-review {
        max-width: 100% !important;
    }
    .formContainer {
        padding: 20px;
        max-width: 100%;
    }
}

/* Media Query for devices with width between 768px and 1024px (tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
    .formContainer-review {
        max-width: 85% !important;
    }
    .formContainer {
        padding: 15px;
        max-width: 90%;
    }
}