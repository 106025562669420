/* Ensure the navigation bar and toggle button are properly positioned */
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 900;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional shadow for better visibility */
}

/* Adjust navbar header for spacing */
.navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  /* Add padding to the sides */
}

/* Navbar brand styling */
.navbar-brand {
  display: flex;
  align-items: center;
}

/* Style the navbar collapse for different screen sizes */
.navbar-collapse {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

/* Flex display adjustments */
.navbar-nav {
  display: flex;
  align-items: center;
  margin: 0;
}

/* Left and right nav alignments */
.navbar-left {
  display: flex;
  margin-right: auto;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-open-left {
  background-color: red !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar-collapse {
    flex-direction: column;
    position: absolute;
    /* Overlay menu */
    top: 100%;
    /* Position it below the header */
    left: 0;
    width: 100%;
    background-color: #fff;
    max-height: 70vh;
    /* Limit height to enable scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 900;
    display: none;
    /* Hidden by default */
    /* Add transition for smooth opening/closing */
    transition: transform 0.3s ease;
    transform: translateY(-100%);
    /* Start above the viewport */
  }

  .navbar-collapse.in {
    display: flex;
    /* Show when toggled */
    transform: translateY(0);
    /* Slide down into view */
  }

  /* Ensure the navbar items stack vertically on mobile */
  .navbar-nav {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  /* Center align nav items */
  .navbar-left,
  .navbar-right {
    width: 100%;
    margin-right: 0;
    justify-content: center;
    padding: 1rem;
  }

  .nav-right-flex {
    padding-bottom: 0.5rem;
  }

  /* Hide the toggle button when menu is open */
  .navbar-toggle {
    display: none;
  }
}


.dropdown-menu-user-list {
  z-index: 900;
}

