.about-us {
  width: 100%;
  padding: 40px 0;
  background-color: #f7f7f7;
  font-family: "Poppins",'sans-serif';
  height: 100vh;
}

.about-about {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.about-pic {
  width: 45%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
}

.about-text {
  width: 50%;
}

.about-text h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.about-text h5 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #555;
}

.about-text h5 span {
  color: #ff545a;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

@media screen and (max-width: 1024px) {
  .about-about {
    flex-direction: column;
    text-align: center;
  }

  .about-pic {
    width: 70%;
    margin-bottom: 30px;
  }

  .about-text {
    width: 80%;
  }

  .about-text h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .about-about{
    padding-top: 0rem;
  }
  .about-pic {
    width: 90%;
  }

  .about-text {
    width: 90%;
  }

  .about-text h2 {
    font-size: 2.5rem;
  }

  .about-text h5 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .about-text h2 {
    font-size: 2rem;
  }

  .about-text h5 {
    font-size: 1rem;
  }

  .about-text p {
    font-size: 0.9rem;
  }
}