.city-filter-bar {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    border : 1px solid #ff545a;
    font-family: 'Poppins',sans-serif;
  }
  
  .city-filter-bar h5 {
    margin-bottom: 15px;
    color: #333;
    font-weight: 600;
  }
  
  .city-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .city-item {
    padding: 10px 15px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .city-item:hover {
    background-color: #ff545a;
  }
  
  .city-item.active {
    background-color: #ff545a;
    color: #fff;
    border-color: #ff545a;
  }
  
  .reset-button {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reset-button:hover:not(:disabled) {
    background-color: #5a6268;
  }
  
  .reset-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .city-filter-bar {
      padding: 15px;
    }
  
    .city-item {
      padding: 8px 12px;
    }
  }