/* @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap'); */
.card-offre-list {
  /* width: 90%; */
  width: 350px;
  height: 100%;
  padding-bottom: 1.25rem;
}

.card-offre {
  background-color: #FFF;
  border-radius: 5px;
  border: 1px solid #78787827;
  overflow: hidden;
  padding: 1.25rem;
  position: relative;
  transition: .15s ease-in;
  cursor: pointer;
}

.card-offre:hover,
.card-offre:focus-within {
  box-shadow: 0 0 0 2px #EC4646, 0 10px 60px 0 rgba(0, 0, 0, .1);
  transform: translateY(-5px);
}

.card-offre-image {
  border-radius: 5px;
  overflow: hidden;
  height: 220px;
  /* width: 305px; */
}

.card-offre-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.card-offre-image-supplementaire {
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-bottom: 0;
  min-height: 30px;
}

.photo-count {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #FFF;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.card-offre-header {
  margin-top: .7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-offre-header h1 {
  font-weight: 600;
  font-size: 1.175rem;
  line-height: 1.25;
  padding-right: 1rem;
  padding-bottom: .5rem;
  color: inherit;
}

.card-offre-person {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 1rem;
  color: #787878;
  padding-bottom: 1rem;
  /* margin-top: 1.25rem; */
}

.card-offre-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-offre-date {
  display: flex;
  align-items: center;
  gap: 0.3em;
  padding-bottom: 1rem;
  color: #787878;
  font-size: 12px;
}

.card-offre-person-initial {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #EC4646;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: .75rem;
}

.card-offre-person p {
  margin: 0;
  font-size: 16px;
}

/* <<<<<<< HEAD
.icon-button:hover, .icon-button:focus {
  background-color: #EC4646;
  color: #FFF;
}

.card-offre-footer {
  margin-top: 1.25rem;
  border-top: 1px solid #ddd;
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
======= */
.card-offre-person-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: .75rem;
}

.card-offre-footer {
  /* margin-top: 1.25rem; */
  padding-top: 1rem;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-offre-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #787878;
  font-size: 0.9rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.473);
  padding-bottom: 1rem;
}

.card-offre-meta-item {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.card-offre-meta svg {
  margin-right: 0.25rem;
}

/* <<<<<<< HEAD
.card-offre-meta:first-child:after {
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: currentcolor;
  margin-left: .75rem;
  margin-right: .75rem;
}

.card-offre-meta svg {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-right: .25em;
======= */
.explore-rating-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* gap: 0.5rem; */
  font-size: 0.9rem;
  /* background-color: aqua; */
  /* max-width: 100%;
  max-width: 350px; */
}

.verification-status-valide {
  display: inline-block;
  padding: 2px 8px;
  background-color: #4CAF50;
  color: white;
  border-radius: 12px;
  font-size: 0.8rem;
  align-self: flex-start;
}


.offre-status {
  display: inline-block;
  padding: 2px 8px;
  color: #4CAF50;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card-offre-list {
    width: 100%;
    margin-inline: 10px;
  }
}


.image-container {
  position: relative;
  width: 100%;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.nav-button-left {
  left: 15px;
}

.nav-button-right {
  right: 15px;
}

.nav-button svg {
  margin-top: 8px;
  width: 24px;
  height: 24px;
  color: #333;
}

.thumbnail-container {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 10px 0;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail.active {
  border-color: #ff545a;
}