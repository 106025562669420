/* ProfileCard.css */
.profile-card {
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  max-width: 70%;
  margin-top: 20px;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.profile-pic {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  object-fit: cover;
  margin-bottom: 15px;
}

.profile-info {
  text-align: center;
}

.profile-info h1 {
  margin: 0;
  color: #333;
  font-size: 28px;
  font-weight: 900;
}

.profile-card h2 {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  margin-top: 0;
}

.profile-info p {
  margin: 5px 0;
  color: #666;
}

.verification {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.verified {
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  margin: 5px;
}

.contact-btn {
  background-color: #ff545a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.contact-btn:hover {
  background-color: #0056b3;
}

.housing-info,
.about,
.languages,
.interests {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.about p {
  color: #666;
  padding: 8px 0;
}

.housing-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 10px 0;
}

.detail-item {
  display: flex;
  align-items: center;
}

.detail-item svg {
  margin-right: 10px;
  color: #ff545a;
}

.languages ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
}

.languages li {
  background-color: #e9ecef;
  padding: 5px 15px;
  border-radius: 5px;
}

.interest-group {
  margin-bottom: 15px;
}

.interest-group h3 {
  color: #555;
  margin-bottom: 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  border: 1px solid #ff545a;
  color: #000;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 5px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .profile-card {
    padding: 15px;
    max-width: 95%;
  }

  .housing-details {
    grid-template-columns: 1fr;
  }

  .profile-pic {
    width: 80px;
    height: 80px;
  }

  .profile-info h1 {
    font-size: 24px;
  }

  .profile-card h2 {
    font-size: 20px;
  }
  

  .profile-info p {
    font-size: 14px;
  }

  .contact-btn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .verified {
    font-size: 10px;
    padding: 4px 8px;
  }

  .housing-info,
  .about,
  .languages,
  .interests {
    padding: 15px;
  }

  .detail-item svg {
    font-size: 20px;
  }

  .languages li {
    padding: 4px 10px;
  }

  .tag {
    padding: 4px 10px;
    font-size: 12px;
  }
}