@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");


.card-demand {
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  background-color: #ffffff;
  padding: 25px 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
  /* width: 350px; */
  /* max-width: 500px; */
  width: 100%;
  height: 300px;
  margin-bottom: 8px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  /* gap: 7px; */
  /* justify-content: space-; */
}

.card-header-demand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}


.card-demande-title {
  font-size: 18px;
  font-weight: 600;
}

.card-demande-desc {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.card-demand::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: #ff545a;
  height: 380px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card-demand:hover::before {
  transition-delay: 0.2s;
  transform: scale(40);
}

.card-demand:hover .card-demande-title {
  color: #ffffff;
  transition-delay: 0.2s;
}

.card-demand:hover .card-demande-desc {
  color: #ffffff;
  transition-delay: 0.2s;
}

.card-demande-title .card-demand:hover {
  color: #ffffff;
  cursor: pointer;
}

.card-demand:hover p {
  color: #ffffff;
  transition-delay: 0.2s;
}

.card-demand:hover ul li span:last-child {
  color: #fff;
  transition-delay: 0.2s;
}

.card-demand:hover ul li span:first-child {
  background-color: #00000046;
  color: #fff;
  transition-delay: 0.2s;
}

/* Medium screens (tablets) */
@media (max-width: 768px) {
  .card-demand {
    width: 100%;
    padding: 20px 15px;
    margin: 10px auto;
  }


  .card-demand p,
  .card-demand ul li span {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .card-demand {
    max-width: 100%;
    /* max-height: none;
     */
    height: 330px;
    padding: 15px 10px;
  }


  .card-demand p,
  .card-demand ul li span {
    font-size: 12px;
  }

  .card-demand ul {
    align-items: flex-start;
  }

  .card-demand ul li {
    margin: 5px 0;
  }
}

.card-demande-info {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  position: absolute;
  bottom: 12px;
}

.card-demande-info li {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: calc(50% - 5px); /* 50% width minus half of the gap */
}

.card-demande-info li span:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background: #ff545a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.card-demande-info li span:first-child svg {
  width: 16px;
  height: 16px;
  color: #fff;
}

.card-demand:hover ul li span:first-child svg {
  color: #fff;
  transition-delay: 0.2s;
}

.card-demande-info li span:last-child {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .card-demande-info {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .card-demande-info li {
    font-size: 12px;
    padding: 6px 10px;
  }

  .card-demande-desc {
    padding-right: 8px;
  }
}


/* @media (min-width: 768px) and (max-width: 1024px) {
  .card-demand {
    width: 100%;
    padding: 20px 15px;
    background-color: green;
  }

  .card-demand p,
  .card-demand ul li span {
    font-size: 14px;
  }
} */