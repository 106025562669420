/* AuthPages.css */
:root {
  --primary-color: #ff545a;
  --text-color: #697a8d;
  --bg-color: #f5f5f9;
}

.auth-page {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
}

.auth-page::-webkit-scrollbar {
  display: none;
}

.auth-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 40px;
  /* padding-bottom: 0; */
  width: 100%;
  max-width: 550px;
}

.auth-page .logo {
  text-align: center;
  margin-bottom: 20px;
}

.auth-page .logo img {
  max-width: 100px;
}

 .logo {
  text-align: center;
  margin-bottom: 20px;
}

 .logo img {
  max-width: 100px;
}

.auth-page h2 {
  color: #32475c;
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
}

.auth-page p {
  margin-bottom: 0px;
  text-align: center;
}

.auth-page .input-group {
  margin-bottom: 20px;
  width: 100%;
}

.auth-page .input-group input:focus {
  outline: none;
  border: 2px solid #ff545a;
}

.auth-page label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
}

.auth-page input[type="text"],
.auth-page input[type="password"],
.auth-page input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #d9dee3;
  border-radius: 5px;
  font-size: 16px;
}

.auth-page .forgot-password {
  display: block;
  text-align: right;
  color: var(--primary-color);
  text-decoration: none;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.auth-page .forgot-password:hover {
  text-decoration: underline;
}

.auth-page .auth-submit-btn {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-page .auth-submit-btn:hover {
  background-color: #ff545ae0;
}

.auth-page .auth-alternate-action {
  text-align: center;
  margin-top: 20px;
}

.auth-page .auth-alternate-action a {
  color: var(--primary-color);
  text-decoration: none;
}

.auth-page .input-group .error {
  border-color: #ff0000;
}

.auth-page .error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}

.auth-page .auth-submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.auth-page .alert {
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

.auth-page .error {
  background-color: #ffe0e0;
  border: 1px solid #f5c2c7;
  color: #842029;
}

.auth-page .success {
  background-color: #d1e7dd;
  border: 1px solid #badbcc;
  color: #0f5132;
}

.auth-page .remember-me {
  /* background-color: green; */
  display: flex ;
  align-items: baseline;
  justify-content: flex-start;
  gap: 15px;
}



/* Responsive design */
/* @media (max-width: 768px) {
  .auth-card {
    padding: 20px;
  }

  .auth-page {
    min-height: auto;
  }
} */

/* Responsive design */
/* @media (min-width: 768px ) and (max-width : 1024px) {
  .auth-card {
    padding: 20px;
  }

  .auth-page {
    min-height: 100vh;
  }
} */
