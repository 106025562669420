/*==================================
* Author        : "ThemeSine"
* Template Name : Listrace  HTML Template
* Version       : 1.0
==================================== */

/*==================================
font-family: 'Roboto', sans-serif;
==================================== */


/*=========== TABLE OF CONTENTS ===========
1.  General css (Reset code)
2.  Header-top
3.  Top-area
4.  Welcome-hero
5.  List-topics
6.  Works  
7.  Explore
8.  Reviews
9.  Counter
10. Blog
11. Subscribe
12. Footer
==========================================*/

/*-------------------------------------
		1.General css (Reset code)
--------------------------------------*/
* {
    padding: 0;
    margin: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #a09e9c;
    text-transform: initial;
    margin: 0 auto;
    overflow-x: hidden;
}

a,
a:hover,
a:active,
a:focus {
    display: inline-block;
    text-decoration: none;
    color: #343a3f;
    font-size: 16px;
    padding: 0;
    font-weight: 500;
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: #343a3f;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

p {
    margin: 0;
    color: #767f86;
    font-size: 14px;
    line-height: 1.8;
    text-transform: initial;
}

img {
    border: none;
    max-width: 100%;
    height: auto;
}

ul {
    padding: 0;
    margin: 0 auto;
    list-style: none;
}

ul li {
    list-style: none;

}

select,
input,
textarea,
button {
    box-shadow: none;
    outline: 0 !important;
}

button {
    background: transparent;
    border: 0;
    font-size: 12px;
}

html,
body {
    height: 100%;
}

[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: opacity 0.3s 0.3s ease;
    -moz-transition: opacity 0.3s 0.3s ease;
    -ms-transition: opacity 0.3s 0.3s ease;
    -o-transition: opacity 0.3s 0.3s ease;
    transition: opacity 0.3s 0.3s ease;
    opacity: 0;
}

/* section-header */
.section-header {
    position: relative;
    text-align: center;
}



.section-header h2 {
    color: #505866;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
}

.section-header h2 span {
    text-transform: lowercase;
}

.section-header p {
    font-size: 16px;
}

/* section-header */
.fix {
    position: relative;
    clear: both;
}

/*=============Style css=========*/

button.default {
    margin: 10px;
    background-color: #ff545a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

button:hover.default,
button:focus.default {
    background-color: #e74c3c;
    color: #fff;
}

/*-------------------------------------
        2. Header-top
--------------------------------------*/
.header-top {
    border-bottom: 1px solid #f6f8fa;
}

.header-top ul li {
    display: inline-block;
}

.header-top-left li,
.header-top-right li {
    display: inline-block;
    
}

/*.select-opt*/
.select-opt,
.header-top-contact {
    padding: 10px 18px;
    border-right: 1px solid #f6f8fa;
}

.header-top-contact {
    border-right: 0;
    border-left: 1px solid #f6f8fa;
    font-size: 12px;
}

.select-opt select {
    border: none;
    cursor: pointer;
    color: #a7b0ba;
    font-size: 12px;
    font-weight: 300;
}

.select-opt option {
    color: #a7b0ba;
    text-transform: uppercase;
}

.select-opt a span.lnr-magnifier:before {
    font-size: 12px;
    color: #373939;
}

.select-opt a span.lnr-magnifier:before {
    font-size: 12px;
    color: #373939;
}

/*.select-opt*/

.header-top-right ul li a {
    color: #a7b0ba;
    font-size: 12px;
    font-weight: 300;
}


/*-------------------------------------
        3. Top-area
--------------------------------------*/


nav.navbar.bootsnav {
    background-color: #fff;
    border-bottom: transparent;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

/*.navbar-brand*/
.navbar-header a.navbar-brand,
.navbar-header a.navbar-brand:hover,
.navbar-header a.navbar-brand:focus {
    display: inline-block;
    color: #1b1e20;
    font-size: 20px;
    font-weight: 700;
    padding: 34px 0px;
    text-transform: capitalize;
}

.navbar-header a.navbar-brand span {
    display: inline-block;
    color: #ff545a;
    text-transform: capitalize;
}

.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:hover,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:focus {
    padding: 30px 0px;
}

/*.navbar-brand*/

/*.nav li*/
nav.navbar.bootsnav ul.nav>li>a {
    color: #859098;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

nav.navbar.bootsnav ul.nav>li>a {
    padding: 34px 21px;
}

nav.navbar.bootsnav ul.nav>li>a:hover,
nav.navbar.bootsnav ul.nav>li>a:focus {
    color: #ff545a;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #ff545a;
}

nav.navbar.bootsnav ul.nav>li.dropdown>a.dropdown-toggle:after {
    content: "";
}

nav.navbar.bootsnav ul.nav>li.dropdown span {
    font-size: 8px;
    margin-left: 15px;
}

.wrap-sticky nav.navbar.bootsnav.sticked ul.nav>li>a {
    padding: 34px 21px;
}

/*.nav li*/

/*.menu-ui-design*/
.menu-ui-design {
    overflow-y: scroll;
    height: 350px;
}

.menu-ui-design::-webkit-scrollbar {
    width: 5px;
}

.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}

.menu-ui-design::-webkit-scrollbar-thumb {
    background-color: #ff545a;
}

/*.menu-ui-design*/

/*.navbar-toggle */
nav.navbar.bootsnav .navbar-toggle {
    position: relative;
    background-color: transparent;
    border: 1px solid #ff545a;
    padding: 10px;
    top: 0;
}

nav.navbar.bootsnav .navbar-toggle i {
    color: #ff545a;
}



nav.navbar.bootsnav {
    background-color: #fff;
    border-bottom: transparent;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.navbar-header a.navbar-brand,
.navbar-header a.navbar-brand:hover,
.navbar-header a.navbar-brand:focus {
    display: inline-block;
    color: #1b1e20;
    font-size: 30px;
    font-weight: 700;
    padding: 28px 0px;
    text-transform: capitalize;
}

.navbar-header a.navbar-brand span {
    display: inline-block;
    color: #ff545a;
    text-transform: capitalize;
}

.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:hover,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:focus {
    padding: 30px 0px;
}

nav.navbar.bootsnav ul.nav>li>a {
    color: #859098;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.3s linear;
}

nav.navbar.bootsnav ul.nav>li>a {
    padding: 34px 21px;
}

nav.navbar.bootsnav ul.nav>li>a:hover,
nav.navbar.bootsnav ul.nav>li>a:focus {
    color: #ff545a;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #ff545a;
}

.menu-ui-design {
    overflow-y: scroll;
    height: 350px;
}

.menu-ui-design::-webkit-scrollbar {
    width: 5px;
}

.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}

.menu-ui-design::-webkit-scrollbar-thumb {
    background-color: #ff545a;
}

nav.navbar.bootsnav .navbar-toggle {
    position: relative;
    background-color: transparent;
    border: 1px solid #ff545a;
    padding: 10px;
    top: 0;
}

nav.navbar.bootsnav .navbar-toggle i {
    color: #ff545a;
}

.btn.navbar-btn {
    margin: 15px 0;
    background-color: #ff545a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.btn.navbar-btn:hover,
.btn.navbar-btn:focus {
    background-color: #e74c3c;
    color: #fff;
}

/*.navbar-toggle */

/*-------------------------------------
        4.  Welcome-hero
--------------------------------------*/
.welcome-hero {
    position: relative;
    background: url(../images/welcome-hero/banner.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.welcome-hero-txt {
    max-width: 950px;
    margin: 0 auto;
}

.welcome-hero-txt .main-title {
    font-size: 4.5vw;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.welcome-hero-txt .subtitle {
    font-size: 2vw; 
    color: #fcfcfc;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.cta-button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

#cta-button {
    display: inline-block;
    padding: 15px 10px;
    font-size: 16px;
    background-color: #ff545a;
    color: #fff;
    width: 280px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

#cta-button:hover {
    background-color: #b31e23;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
    .welcome-hero-txt .main-title {
        font-size: 54px;
    }
    .welcome-hero-txt .subtitle {
        font-size: 24px;
    }
    #cta-button {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .welcome-hero {
        height: auto;
        min-height: 91vh;
        padding: 60px 0;
    }
    
    .welcome-hero-txt .main-title {
        font-size: 36px;
    }
    .welcome-hero-txt .subtitle {
        font-size: 18px;
    }
    #cta-button {
        font-size: 16px;
        padding: 12px 24px;
        width: 280px;
    }
    .cta-button-container {
        flex-direction: column;
        align-items: center;
    }
}   

@media (max-width: 480px) {
    .welcome-hero-txt .main-title {
        font-size: 28px;
    }
    .welcome-hero-txt .subtitle {
        font-size: 16px;
    }
    #cta-button {
        width: 280px;
        font-size: 11px;
    }
}

.welcome-hero-txt .main-title {
    font-size: 4.5vw;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.welcome-hero-txt .subtitle {
    font-size: 2vw; 
    color: #fcfcfc;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}


@media (max-width: 1200px) {
    .welcome-hero-txt .main-title {
        font-size: 47px;
    }
    .welcome-hero-txt .subtitle {
        font-size: 24px;
    }
    #cta-button {
        font-size: 15px;
    }
}

.welcome-hero-serch-box {
    margin-top: 78px;
    display: flex;
}

.welcome-hero-form {
    display: flex;
    background: #fff;
    height: 70px;
    border-radius: 3px;
    width: 85%;
}

.single-welcome-hero-form {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    padding: 0 30px;
}

.single-welcome-hero-form:first-child {
    border-right: 1px solid #edeff1;
}

.single-welcome-hero-form input {
    margin-left: 10px;
    height: 70px;
    width: 300px;
    border: 0;
    background: transparent;
}

.single-welcome-hero-form input[type="text"] {
    font-size: 14px;
    color: #859098;
    text-transform: capitalize;
    font-weight: 500;
}

.welcome-hero-form-icon {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #252d32;
}

.welcome-hero-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    width: 170px;
    height: 70px;
    background: #ff545a;
    text-transform: capitalize;
    margin-left: 30px;
    border-radius: 3px;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.welcome-hero-btn:hover {
    /*background: #fd4043;*/
    background: #f43032;
}

.welcome-hero-btn svg {
    width: 14px;
    height: auto;
    margin-left: 12px;
}

/*-------------------------------------
        5. List-topics
--------------------------------------*/
.list-topics-content {
    position: relative;
    top: -98px;
    z-index: 1;
    overflow: hidden;
    /* Hide the scrollbar */
    padding-bottom: 20px;
    white-space: nowrap;
    /* Prevent items from wrapping to the next line */
}

.list-topics-content ul {
    display: flex;
    /* Use flexbox for layout */
    flex-wrap: nowrap;
    /* Prevent items from wrapping to the next line */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
    transition: transform 0.3s ease;
    /* Smooth scrolling */
}

.list-topics-content ul li {
    display: inline-block;
    margin-right: 25px;
    /* Add margin between items */
}

.list-topics-content ul li:hover {
    cursor: pointer;
}

.single-list-topics-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    height: 200px;
    border-radius: 3px;
    box-shadow: 0 0px 10px rgba(71, 71, 71, .2);
    transition: .3s linear;
    position: relative;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.single-list-topics-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-image) no-repeat center center;
    background-size: cover;
    transition: filter 0.3s ease;
    z-index: -1;
    /* Ensure the pseudo-element stays behind the content */
}

.single-list-topics-content:hover::before {
    filter: blur(10px);
    /* Adjust the blur intensity as needed */
}

.single-list-topics-icon,
.single-list-topics-content h2,
p {
    position: relative;
    z-index: 1;
    /* Ensure content stays above the pseudo-element */
}

.single-list-topics-content h2 {
    font-size: 1.5em;
    color: white;
    text-shadow: 1px 1px 2px black;
    font-weight: bold;
    /* font-family: 'Arial'; */
    /* font-family: 'Poppins', sans-serif; */
}


.single-list-topics-icon [class^="flaticon-"]:before,
.single-list-topics-icon [class*=" flaticon-"]:before,
.single-list-topics-icon [class^="flaticon-"]:after,
.single-list-topics-icon [class*=" flaticon-"]:after {
    font-size: 45px;
    color: #343a3f;
}

.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:before,
.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:before,
.single-list-topics-content:hover .single-list-topics-icon [class^="flaticon-"]:after,
.single-list-topics-content:hover .single-list-topics-icon [class*=" flaticon-"]:after {
    color: #fff;
}

.single-list-topics-content:hover h2>a,
.single-list-topics-content:hover p {
    color: #fff !important;
}

/* Navigation Arrows */
.carousel {
    position: relative;

}

.carousel .forward,
.carousel .backward {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 3em;
    height: 3em;
    margin-top: -1.5em;
    cursor: pointer;
    background: #ff545a;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.35s ease-in-out;
}

.carousel .forward:hover,
.carousel .backward:hover {
    background-color: rgba(239, 131, 118, 0.75);
}

.carousel .forward {
    right: -10px;
}

.carousel .backward {
    left: -10px;
}

.carousel .arrow {
    width: 1em;
    height: 1em;
    background: url('../images/arrow.svg') no-repeat center center;
    background-size: contain;
}

.carousel .backward .arrow {
    transform: scaleX(-1);
    /* Flip the backward arrow */
}





/*-------------------------------------
        6. Works 
--------------------------------------*/
.works {
    padding: 0 0 90px;
}

.works-content {
    margin-top: 73px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    /* Space between rows */
}

.last-step {
    display: flex;
    justify-content: center;
    width: 100%;
}

.single-how-works {
    text-align: left;
    padding: 20px 42px;
    border-radius: 3px;
    box-shadow: 0 0px 2px rgba(71, 71, 71, .4);
    transition: .3s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
}

.top-steps .single-how-works {
    width: calc(33.33% - 14px);
    /* Subtracting to account for gap */
    max-width: 400px;
    /* Adjust as needed */
}

.top-steps .step-work {
    max-width: 565px;
}


.last-step .single-how-works {
    width: calc(66.66% - 14px);
    /* Double the width of a top step */
    max-width: 800px;
    /* Adjust as needed */
}

.single-how-works h2 {
    font-size: 44px;
}

/* Media query for responsive design */
@media (max-width: 1024px) {
    .top-steps {
        flex-wrap: wrap;
    }

    .top-steps .step-work,
    .last-step .step-work {
        width: calc(50% - 10px);
        max-width: none;
    }
}

@media (max-width: 768px) {
    .top-steps {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .top-steps .step-work {
        max-width: 100%;
    }

    .top-steps .step-work,
    .last-step .step-work {
        width: 100%;
        min-width: 100%;
    }
}

/* Media query for iPad Air dimensions */
@media (min-width: 768px) and (max-width: 1024px) {
    .top-steps {
        flex-wrap: nowrap;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        /* background-color: red; */
    }

    .top-steps .step-work {
        /* background-color: green; */
        /* margin-bottom: 20px; */

        width: 100%;

    }

    .last-step {
        margin-top: 0px;

    }

    .last-step .step-work {
        /* max-width: none; */

        width: 100%;
    }
}

/* Media query for iPad Air dimensions */
@media (min-width: 768px) and (max-width: 1024px) {
    .top-steps {
        flex-wrap: nowrap;
        width: 100%;
        flex-direction: column;
        gap: 20px;
        /* background-color: red; */
    }

    .top-steps .step-work {
        /* background-color: green; */
        /* margin-bottom: 20px; */

        width: 100%;

    }

    .last-step {
        margin-top: 0px;

    }

    .last-step .step-work {
        /* max-width: none; */

        width: 100%;
    }
}

.single-how-works-icon {
    display: inline-block;
    color: #50616c;
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: #eef2f6;
    border-radius: 50%;
}

.single-how-works h2 {
    font-size: 18px;
    margin: 35px 0 20px;
    color: #505866;
    font-weight: 700;
}

.single-how-works h2 a {
    font-size: 18px;
    margin: 35px 0 20px;
    color: #505866;
    font-weight: 700;
}

.single-how-works h2 a span {
    text-transform: lowercase;
}

.single-how-works p {
    margin-bottom: 25px;
    /* font-family: 'Arial' ; */
    font-family: 'Poppins', sans-serif;

    text-transform: initial;
}

.single-how-works-icon [class^="flaticon-"]:before,
.single-how-works-icon [class*=" flaticon-"]:before,
.single-how-works-icon [class^="flaticon-"]:after,
.single-how-works-icon [class*=" flaticon-"]:after {
    font-size: 35px;
}

.single-how-works:hover .single-how-works-icon [class^="flaticon-"]:before,
.single-how-works:hover .single-how-works-icon [class*=" flaticon-"]:before,
.single-how-works:hover .single-how-works-icon [class^="flaticon-"]:after,
.single-how-works:hover .single-how-works-icon [class*=" flaticon-"]:after {
    color: #ff545a;
}

.welcome-hero-btn.how-work-btn {
    display: inline-block;
    margin: 0;
    width: 100px;
    height: 35px;
    font-size: 12px;
    background: transparent;
    color: #767f86;
    border: 1px solid #d3d6d9;
    border-radius: 3px;
}

.single-how-works:hover h2,
.single-how-works:hover h2 a,
.single-how-works:hover p {
    color: #fff;
}

.single-how-works:hover .single-how-works-icon {
    background: #fff;
}

.single-how-works:hover .welcome-hero-btn.how-work-btn {
    background: #fff;
    color: #ff545a;
}

.single-how-works:hover {
    box-shadow: 0 0px 10px rgba(71, 71, 71, .4);
    background: #ff545a;
}

/*-------------------------------------
        7. Explore
--------------------------------------*/
.explore {
    padding: 117px 0 95px;
    background: #f8fafb;
}

.explore-content {
    margin-top: 78px;
}

.single-explore-item {
    background: #fff;
    border: 1px solid #edeff1;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    height: 500px;
    /* Fixed height */
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.single-explore-img {
    position: relative;
    height: 200px;
    /* Fixed height */
    overflow: hidden;
}

.explore-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.single-explore-txt {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.explore-person {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.explore-person-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.explore-description {
    font-size: 14px;
    line-height: 1.4;
    max-height: 4.2em;
    /* 3 lines */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0;
}

.explore-open-close-part {
    margin-top: auto;
}

.close-btn {
    color: #f63138;
    text-transform: capitalize;
}

.open-btn {
    color: #00c437;
}

.single-explore-item:hover {
    box-shadow: 0 10px 20px rgba(255, 84, 90, 0.2);
}

.image-count {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 84, 90, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
}

.single-explore-img-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.rated-button {
    background: #ff545a;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
}

.single-explore-image-icon-box {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.icon-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.icon-list li {
    margin: 0 5px;
}

.icon-list i {
    color: #fff;
    font-size: 16px;
}

.explore-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #ff545a;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.explore-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #ff545a;
    margin-top: 10px;
    transition: width 0.3s ease;
}

.explore-title:hover::after {
    width: 100%;
}

.explore-rating-price {
    font-size: 14px;
    color: #777f85;
    margin-bottom: 15px;
}

.explore-price-box {
    border-left: 1px solid #dde0e4;
    border-right: 1px solid #dde0e4;
    padding: 0 10px;
    margin: 0 8px;
}

.explore-price {
    color: #ff545a;
    font-weight: bold;
}

.explore-type {
    background: #ff545a;
    color: #fff;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
}

.explore-footer {
    margin-top: auto;
    border-top: 1px solid #e1e5eb;
    padding-top: 15px;
}

.explore-map-icon {
    text-align: right;
}

.explore-map-icon a {
    color: #767f86;
    margin: 0 5px;
    font-size: 16px;
}

.explore-map-icon a:hover {
    color: #ff545a;
}


/*-------------------------------------
        8. Reviews
--------------------------------------*/
.reviews {
    padding: 117px 0 75px;
}

.reviews-content {
    margin-top: 36px;
    /* */
    /* display: flex;
    justify-content: center;
    gap: 30px; */
}

/*single-testimonial-box */
.single-testimonial-box {
    padding: 50px 30px;
    box-shadow: 0 0px 4px rgba(71, 71, 71, .2);
    overflow-x: hidden;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.single-testimonial-box:hover {
    box-shadow: 0 0px 8px rgba(21, 19, 19, .2);
}

.slick-current .single-testimonial-box {
    box-shadow: 0 10px 20px rgba(21, 19, 19, .2);
}

/*testimonial-description*/
.single-testimonial-box {
    width: 404px;
    background: #fff;
}

/* testimonial-info */
.testimonial-info {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.testimonial-img {
    position: relative;
    top: 11px;
    margin-right: 5px;
    border-radius: 50%;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.testimonial-person {
    margin-left: 15px;
    margin-top: 11px;
}

.testimonial-person h2 {
    color: #505866;
    font-size: 18px;
}

.testimonial-person h4 {
    color: #a2a5ab;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
}

.testimonial-person-star i {
    color: #ffda2b;
    margin: 9px 4px 0 0;
}

/* testimonial-info */


/* testimonial-comment */
.testimonial-comment {
    margin-top: 18px;
}

.testimonial-comment p {
    color: #8d939e;
    font-size: 14px;
    font-weight: 300;
}

/* testimonial-comment */


/*.slick-slide*/
.slick-initialized .slick-slide {
    display: block;
    padding: 40px 0;
}

.slick-slide.slick-cloned {
    outline: 0 !important;
}

.slick-slide {
    margin: 0px 10px;
}

.slick-slide {
    transition: all ease-in-out .3s;
    opacity: .5;
}

.slick-active {
    opacity: .5;
}

.slick-current {
    opacity: 1;
}

/*.slick-slide*/

/*-------------------------------------       
        9.  Counter
--------------------------------------*/
.statistics {
    position: relative;
    display: flex;
    align-items: center;
    background: url(../../assets/images/counter/counter-banner.jpg)no-repeat fixed;
    background-position: center;
    background-size: cover;
    padding: 127px 0 120px;
}

.statistics:before {
    position: absolute;
    content: '';
    background: rgba(75, 75, 75, .60);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

/* single-ststistics-box */
.appsLand-btn .single-ststistics-box {
    text-align: center;
    margin-bottom: 30px;

    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* single-ststistics-box */
.statistics-content {
    /* display: flex; */
    /* justify-content: center; */
    /* background-color: red; */
    color: #fff;
    font-size: 60px;
}

.statistics-content span {
    margin-left: 5px;
}


.single-ststistics-box h3 {
    color: #fff;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 500;
}

.statistics .counter {
    /* color: #ff545a; */
    font-size: larger;
    font-weight: bold;
    letter-spacing: 3px;
}


.statistics .stat-label {
    color: #fff;
    font-weight: bold;

}

/*-------------------------------------
        10. Blog
--------------------------------------*/
.blog {
    padding: 120px 0 90px;
}

.blog-content {
    margin-top: 80px;
}

.single-blog-item {
    margin-bottom: 30px;
    box-shadow: 0 0px 5px rgba(71, 71, 71, .2);
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.single-blog-item-txt {
    padding: 25px 28px 27px;
}

.single-blog-item-txt h2 a {
    text-transform: initial;
    line-height: 1.8;
}

.single-blog-item-txt h4 {
    font-size: 14px;
    color: #8f949d;
    font-weight: 400;
    margin: 12px 0 20px;
}

.single-blog-item-txt h4 a {
    font-size: 14px;
    padding-right: 14px;
    border-right: 1px solid #dde0e4;
    margin-right: 15px;
    text-transform: uppercase;
}

.single-blog-item:hover h2 a {
    color: #ff545a;
}

.single-blog-item:hover {
    box-shadow: 0 10px 20px rgba(21, 19, 19, .4);
}


/*-------------------------------------
        11. Subscribe
--------------------------------------*/
.subscription {
    background: #f8fafb;
    padding: 150px 0;
}

/*subscribe-title*/
.subscribe-title {
    margin-bottom: 52px;
}

.subscribe-title h2 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
}

.subscribe-title p {
    color: #7b8088;
    font-size: 16px;
    font-weight: 500;
    margin-top: 28px;
}

/*subscribe-title*/


/*custom-input-group*/
.subscription-input-group {
    position: relative;
    text-align: center;
    max-width: 630px;
    margin: 0 auto;
    /* border: #000 1px solid; */
}

.subscription-input-group form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.subscription-input-group form textarea {
    max-width: 630px;
    /* min-width: 630px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    min-height: 150px;
    gap: 20px;
}

.subscription-input-group .subscription-input-form {
    display: inline-block;
    width: 630px;
    height: 60px;
    padding-left: 30px;
    font-size: 16px;
    color: #000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #fff;
    box-shadow: 0 0px 10px rgba(21, 19, 19, .1);
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;

}

.subscription-input-group:hover .subscription-input-form {
    box-shadow: 0 5px 20px rgba(21, 19, 19, .4);
}

.subscription-input-group:hover .subscription-input-form {
    box-shadow: 0 5px 20px rgba(21, 19, 19, .4);
}

/*custom-input-group*/

/*appsLand-btn*/
.appsLand-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: #ff545a;
    display: inline-block;
    width: 180px;
    height: 60px;
    line-height: 60px;
    text-decoration: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-transform: capitalize;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.appsLand-btn:hover,
.appsLand-btn:focus,
.appsLand-btn:active {
    text-decoration: none;
    outline: none;
}

.appsLand-btn:hover {
    box-shadow: 0 5px 10px rgba(71, 71, 71, .4);
    background: #f43032;
}

/*appsLand-btn*/

/*-------------------------------------
        12. Footer
--------------------------------------*/
.footer-menu {
    padding: 45px 0;
}

.footer-menu .navbar-header {
    padding: 0;
}

.footer-menu .navbar-header a.navbar-brand,
.footer-menu.footer-menu .navbar-header a.navbar-brand:hover,
.footer-menu .navbar-header a.navbar-brand:focus {
    padding: 0;
    height: 0;
}

.footer-menu ul.footer-menu-item {
    text-align: right;
}

.footer-menu ul.footer-menu-item li {
    display: inline-block;
}

.footer-menu ul.footer-menu-item li a {
    color: #859098;
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 40px;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.footer-menu ul.footer-menu-item li a:hover {
    color: #f43032;
}

.hm-footer-copyright {
    padding: 40px 0;
    border-top: 1px solid #e1e5eb;
}

.hm-footer-copyright p,
.hm-footer-copyright p a {
    color: #a5adb3;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.footer-social {
    text-align: right;
}

.footer-social .fa-phone:before {
    position: relative;
    top: 2px;
}

.footer-social a,
.footer-social span {
    display: inline-block;
    color: #afb4bf;
    font-size: 14px;
    margin-left: 15px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.footer-social a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #eef2f6;
    text-align: center;
    border-radius: 50%;
}

.footer-social span {
    margin-right: 15px;
    margin-left: 0;
    color: #a5adb3;
}

.footer-social span:hover {
    color: #ff545a;
}

.footer-social a:hover {
    background: #ff545a;
    color: #fff;
}

/*===============================
    Scroll Top
===============================*/
#scroll-Top .return-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: none;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    background: #ff545a;
    border: 1px solid #ff545a;
    border-radius: 50%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 2;
}

#scroll-Top .return-to-top:hover {
    background: #f43032;
    border: 1px solid #ff545a;
}

#scroll-Top .return-to-top i {
    position: relative;
    bottom: 0;

}

#scroll-Top .return-to-top i {
    position: relative;
    animation-name: example;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}

@keyframes example {
    0% {
        bottom: 0px;
    }

    100% {
        bottom: 7px;
    }
}

.reactContent {
    min-height: 80vh;
    padding-top: 28px; 

}

@media (max-width: 993px) {
    .reactContent {
        min-height: 90vh;
        padding-top: 12px;
    }
}

/* add by imad */
input[readonly]:focus {
    color: #ff545a;
    border: 2px solid #ff545a;
    outline: none;
}

/* flex  */

.flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logement-item {
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .logement-item {
        /* background-color: green; */
        /* border: 1px solid #50616c; */
    }
}

.filter-wrapper {
    width: 100%;
    /* background-color: #00c437; */
}

.filter-container {
    /* background-color: gold; */
    max-width: 80%;
    margin: 0 auto;
}



.explore-grid-demande-filter {
    display: grid;
    gap: 10px;
}

.explore-grid-offre-filter {
    width: 100%;
    display: flex;
    row-gap: 10px;
    column-gap: 5px;
    flex-wrap: wrap;
    margin: 0 auto;
}

@media (min-width: 1025px) {
    .explore-grid-demande-filter {
        grid-template-columns: repeat(3, 1fr);
    }

    .explore-grid-offre-filter {
        /* justify-content: center; */
    }

    .explore-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .explore-grid-demande-filter {
        margin-top: 15px;
        grid-template-columns: repeat(2, 1fr);
    }

    .explore-grid-offre-filter {
        /* grid-template-columns: repeat(2, 1fr); */
        gap: 0;
        margin-top: 1rem;
        /* justify-content: center; */
        width: 90%;
    }

    .filter-container {
        max-width: 100%;
        margin: 0 auto;
    }

    .filter-container-header {
        padding: 0 30px;
    }

    .filter-container .explore-grid-demande-filter {
        /* max-width: 100%; */
        padding: 0 30px;

    }

    .left-filter .city-filter-bar {
        margin: 5px 30px;
    }
}

@media (max-width: 767px) {

    .filter-container {
        max-width: 100%;
    }
    
    .filter-container .explore-grid-demande-filter {
        /* max-width: 100%; */
        padding: 0 10px;

    }

    

    .filter-container-header {
        padding: 0 10px;
    }

    .left-filter .city-filter-bar {
        margin: 5px 10px;
    }
}  