/* Base styles */
.terms-container {
    max-width: 1500px;
    margin: 0 auto;
    /* height: 100vh; */
    padding: 20px;
    font-family: "Poppins", sans-serif;
    line-height: 1.6;
}

.terms-title {
    color: #ff4b4b;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
}

.terms-last-updated {
    color: #888;
    font-size: 14px;
    margin-bottom: 20px;
}

.terms-content {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.terms-content p {
    margin-bottom: 15px;
}

.terms-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.terms-button {
    padding: 10px 30px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

.terms-button a {
    text-decoration: none;
    color: #ff4b4b;
}

.terms-button.decline {
    background-color: #fff;
    color: #ff4b4b;
    border: 1px solid #ff4b4b;
}

.terms-button.accept {
    background-color: #7fe5d6;
    color: #fff;
}

.terms-button:hover {
    opacity: 0.8;
}

/* Media query for mobile devices (up to 767px) */
@media (max-width: 767px) {
    .terms-container {
        max-height: 100%;
        overflow: auto;
        padding: 10px;
    }

    .terms-title {
        font-size: 20px;
    }

    .terms-content {
        padding: 15px;
    }

    .terms-button {
        padding: 8px 20px;
        font-size: 14px;
    }
}

/* Media query for tablet devices (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .terms-container {
        padding: 15px;
    }

    .terms-title {
        font-size: 22px;
    }

    .terms-content {
        padding: 18px;
    }

    .terms-button {
        padding: 9px 25px;
        font-size: 15px;
    }
}